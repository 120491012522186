<template>
    <b-overlay :show='showLoader'>
        <b-card class="your-units mb-0">
            <h1 class="font-title">Vaše enote</h1>
            <b-table class="table my-1" responsive striped hover 
                     :items="filtered" 
                     :fields="fields" 
                     :per-page="perPage"
                     :current-page="currentPage"
                     ref="your-units-table" 
                     @row-clicked="onRowClick"
                     :tbody-tr-class="rowClass">
                <template #cell(naziv)="row">
                    <span v-if="row.item.vrsta ==='L+U'">
                        {{ row.item.naziv }} (Lastnik/Uporabnik)
                    </span>
                    <span v-else>
                        {{ row.item.naziv }} ({{ row.item.vrsta === 'L' ? 'Lastnik' : 'Uporabnik'  }})
                    </span>
                </template>
                <template #cell(actions)="row">
                    <span v-if="Object.keys(row.item).length !== 0">
                        <b-button class="button-pointer" @click="$router.push('/e-upravnik/oglasna-deska/' + row.item.objekt_sifra); $scrollToTop()"><fa style="color: #72a5d8 !important;" icon="hand-point-up" size="2x"/></b-button>
                    </span>
                </template>
                <template v-if="filtered.length === 0" slot="bottom-row">
                    <td :colspan="fields.length">
                        <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                    </td>
                </template>
            </b-table>
            <b-pagination
                v-if="filtered.length > perPage"
                v-model="currentPage"
                :total-rows="filtered.length"
                :per-page="perPage"
                aria-controls="my-table"
                :next-class="'hidden'" 
                :prev-class="'hidden'"
                id="pagination-your-units"
                :pills="true"
            ></b-pagination>

            <h1 v-if="activeUnit" class="font pt-3">{{activeUnit.naziv}}</h1>
            <b-row v-if="activeUnit" class="mt-1 data">
                <b-col cols="12" md="6" lg="4" class="mt-1 border-separator pb-1 pb-md-0">
                    <div class="font-bottom"> <div class="text-bold d-inline">Št. enote:</div> {{activeUnit.sifra}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">GURS št.</div> stavbe: {{activeUnit.stev_stavbe}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">GURS št.</div> dela stavbe: {{activeUnit.stev_dela_stavbe}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">GURS št.</div> enote: {{activeUnit.zaporedna}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Parcela:</div> {{activeUnit.parcela}}</div>
                </b-col>
                <b-col cols="12" md="6" lg="4" class="mt-1 border-separator pb-1 pb-md-0">
                    <div class="font-bottom"> <div class="text-bold d-inline">Solastniški delež:</div> {{activeUnit.solastniski_delez.toFixed(2)}}%</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Velikost bruto:</div> {{activeUnit.velikost_bruto.toFixed(2)}} m<sup>2</sup></div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Velikost neto:</div> {{activeUnit.velikost_neto.toFixed(2)}} m<sup>2</sup></div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Ogrevalna površina:</div> {{activeUnit.velikost_kub.toFixed(2)}} m<sup>2</sup></div>
                </b-col>
                <b-col cols="12" md="6" lg="4" class="mt-1">
                    <div class="font-bottom"> <div class="text-bold d-inline">Vrsta enote:</div> {{activeUnit.vrsta_enote}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Vrsta prostora:</div> {{activeUnit.vrsta_prostora}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Št. oseb:</div> {{activeUnit.stev_oseb}}</div>
                    <div class="font-bottom"> <div class="text-bold d-inline">Št. živali:</div> {{activeUnit.stev_zivali}}</div>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BCard, BRow, BCol, BButton, BTable, BOverlay, BPagination} from 'bootstrap-vue'

    export default {
        components: {BCard, BRow, BCol, BButton, BTable, BOverlay, BPagination},
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                options:{
                    naziv: [],
                    stev_dela_stavbe: [],
                    zaporedna: [],
                    stev_stavbe: [],
                    uporabnik_naziv: []
                },
                data:{
                    naziv: '',
                    stev_dela_stavbe: '',
                    zaporedna: '',
                    stev_stavbe: '',
                    uporabnik_naziv: ''
                },
                showLoader: false,
                units: [],
                activeUnit: null,
                fields: [
                    { key: 'naziv', label: 'Objekt / št. enote', sortable: false, class: 'text-left' },
                    /*{ key: 'inventarna_stevilka', label: 'Inv. št', sortable: false, class: 'text-left', type: 0},*/
                    /*{ key: 'stev_stavbe', label: 'GURS št. stavbe', sortable: false, class: 'text-left' },*/
                    { key: 'zaporedna', label: 'GURS št. enote', sortable: false, class: 'text-left' },
                    /*{ key: 'stev_dela_stavbe', label: 'GURS', sortable: false, class: 'text-left' },*/
                    { key: 'actions', label: 'Oglasna deska', sortable: false, class: 'text-left' }
                ],
                filterTrigger: 0
            }
        },
        methods:{
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.selected) return 'row-selected'
            },
            updateFilters() {
                const tmp = {
                    stev_dela_stavbe: [],
                    zaporedna: [],
                    stev_stavbe: [],
                    uporabnik_naziv: []
                }

                for (const item of this.filtered) {

                    let index = tmp.zaporedna.findIndex(x => x === item.zaporedna) 

                    if (index === -1) {
                        tmp.zaporedna.push(item.zaporedna)
                    }

                    index = tmp.stev_stavbe.findIndex(x => x === item.stev_stavbe) 

                    if (index === -1) {
                        tmp.stev_stavbe.push(item.stev_stavbe)
                    }

                    index = tmp.uporabnik_naziv.findIndex(x => x === item.uporabnik_naziv) 

                    if (index === -1) {
                        tmp.uporabnik_naziv.push(item.uporabnik_naziv)
                    }

                    index = tmp.stev_dela_stavbe.findIndex(x => x === item.stev_dela_stavbe) 

                    if (index === -1) {
                        tmp.stev_dela_stavbe.push(item.stev_dela_stavbe)
                    }

                }

                this.options.zaporedna = tmp.zaporedna
                this.options.stev_stavbe = tmp.stev_stavbe
                this.options.stev_dela_stavbe = tmp.stev_dela_stavbe
                this.options.uporabnik_naziv = tmp.uporabnik_naziv

            },
            onFilterChange() {

                if (!this.data.naziv || this.data.naziv === '') {
                    this.data.naziv =  ''
                    this.data.stev_dela_stavbe =  ''
                    this.data.zaporedna =  ''
                    this.data.stev_stavbe =  ''
                    this.data.uporabnik_naziv =  ''
                } else {
                    this.updateFilters()
                }
                this.filterTrigger++
                this.activeUnit = null

            },
            async loadUnits() {
                try {
                    
                    this.showLoader = true
                    const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                    this.units = []
                
                    if (!res.data ||  res.data.length === 0) {
                        this.showLoader = false
                        return
                    }
                    this.activeUnit =  res.data[0]
                    this.activeUnit.selected = true

                    res.data.forEach((x) => {
                        if (x.vrsta === 'L') {
                            if (res.data.find((element) => element.inventarna_stevilka === x.inventarna_stevilka && element.vrsta !== x.vrsta)) {
                                x.vrsta = 'L+U'
                                this.units.push(x)
                            } else {
                                this.units.push(x)
                            }
                        } else if (res.data.find((element) => element.inventarna_stevilka === x.inventarna_stevilka && element.vrsta !== x.vrsta) === undefined) {
                            this.units.push(x)
                        }
                    })

                    this.units.sort((a, b) => { 
                        if ((a.naziv === b.naziv && a.zaporedna < b.zaporedna) || (a.naziv < b.naziv)) return -1
                        else if (a.naziv > b.naziv) return 1
                        else return 0
                    })

                    for (const unit of this.units) {
                        if (!this.options.naziv.includes(unit.naziv)) this.options.naziv.push(unit.naziv)
                        if (!this.options.stev_dela_stavbe.includes(unit.stev_dela_stavbe)) this.options.stev_dela_stavbe.push(unit.stev_dela_stavbe)
                        if (!this.options.zaporedna.includes(unit.zaporedna)) this.options.zaporedna.push(unit.zaporedna)
                        if (!this.options.stev_stavbe.includes(unit.stev_stavbe)) this.options.stev_stavbe.push(unit.stev_stavbe)
                        if (!this.options.uporabnik_naziv.includes(unit.uporabnik_naziv)) this.options.uporabnik_naziv.push(unit.uporabnik_naziv)
                    }

                    this.options.naziv.sort()
                    this.options.stev_dela_stavbe.sort((a, b) => { return a - b })
                    this.options.zaporedna.sort((a, b) => { return a - b })
                    this.options.stev_stavbe.sort((a, b) => { return a - b })
                    this.options.uporabnik_naziv.sort()
                
                } catch (error) {
                    this.$printError(
                        `Prišlo je do napake pri nalaganju podatkov\n${error.message}`
                    )
                } finally {
                    this.showLoader = false
                }

            },
            onRowClick(item) {
                this.filtered.forEach(row => { row.selected = false })

                this.activeUnit = item
                this.activeUnit.selected = true
            }        
        },
        computed: {
            filtered() {
                // for triggering computed method
                // eslint-disable-next-line no-unused-expressions
                this.filterTrigger

                const filtered = this.units.filter(unit => {
                    return Object.keys(this.data).every(key => {
                        if (key === undefined || key === null || !unit || !String(unit[key]) || this.data[key] === undefined || this.data[key] === null) return true

                        if (key === 'zaporedna' && this.data.zaporedna) {
                            return unit[key] === this.data[key]
                        }

                        if (key === 'stev_dela_stavbe' && this.data.stev_dela_stavbe) {
                            return unit[key] === this.data[key]
                        }

                        if (key === 'stev_stavbe' && this.data.stev_stavbe) {
                            return unit[key] === this.data[key]
                        }
                        
                        return String(unit[key]).toLowerCase().includes(String(this.data[key]).toLowerCase())
                    })
                })

                for (const i of filtered) i.selected = false
                
                return filtered.length > 0 ? filtered : []
            }
        },
        watch: {
            filtered() {
                if (this.filtered.length > 0) {
                    this.activeUnit = this.filtered[0]
                    this.activeUnit.selected = true
                } else this.activeUnit = null
            }
        },
        async mounted() {
            await this.loadUnits()
        }
    }
</script>

<style>
#pagination-your-units.b-pagination-pills .page-item .page-link {
  width: 49px;
  height: 49px;
  background-color: #F8F8F8;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: black;
}
#pagination-your-units.b-pagination-pills .page-item.active .page-link {
  background-color: #72A5D8 !important;
  color: white;
}

.your-units .table tbody tr.row-selected {
    background-color: #F9FBFD !important;
    border-right: 30px solid #F9FBFD !important;
    border-left: 30px solid #F9FBFD !important;
}
.your-units .table tbody tr:hover {
    background-color: #F6F9FC !important;
    border-right: 30px solid #F6F9FC !important;
    border-left: 30px solid #F6F9FC !important;
}
.your-units .text-bold{
    font-weight: bold !important;
    color: black;
    font-size: 1.3rem !important;
}
.your-units .font-bottom{
    color: #515050;
    font-size: 1.2rem !important;
}
.your-units .button-pointer{
    background: transparent !important;
    border: none;
}

.your-units{
    border-radius: 10px !important;
}
.your-units .font-title {
    font-weight: bold;
    font-size: 2.5rem;
    color: #72a5d8 !important;
    padding-bottom: 10px;
} 
.your-units .font {
    font-weight: bold;
    font-size: 1.5rem;
} 
.your-units .card .card-footer {
    border: none;
    padding: 0 !important;
}
.your-units .row-input {
    padding-left: 0;
}
.your-units .table th {
  background-color: #dbe9f5 !important;
  border-top: none;
  border-bottom: none;
  text-transform: none;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}
.your-units .table thead tr {
  border-bottom: 2px solid #72a5d8;
  border-right: 30px solid #dbe9f5 !important;
  border-left: 30px solid #dbe9f5 !important;
  color: #72a5d8 !important;
  font-weight: bold;
  background: #dbe9f5 !important;
}
.your-units .table tbody tr {
  background-color: #eaf3f9 !important;
  border-top: 1px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
}
.your-units .table tfoot {
  background-color: #eaf3f9 !important;
  border-top: 2px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
  border-bottom: 20px solid #eaf3f9;
  font-weight: bold;
}

.your-units .border-separator {
    border-bottom: 1px solid #bfbfbf!important;
    border-right: none !important;
    padding-bottom: 1em;
}

@media only screen and (min-width: 720px) {
    .your-units .border-separator{
        border-bottom: none !important;
        border-right: 1px solid #bfbfbf!important;
    }
}
</style>
